import { planActionConstants } from '../planActionConstants'

import { fetchDataWithCsrf } from '../../services/fetchDataWithCsrf'

function getUserPlansDetails() {
    return (async (dispatch) => {
        dispatch(loadingUserWebinarPlans())
        try {
            let r = await fetchUserWebinarPlans()
            // if (!r.success) {
            //     throw new Error("this is error")
            // }
            dispatch(loadingUserWebinarPlansSuccess(r))
        } catch (error) {
            throw new Error(error)
        }
    })
}
const loadingUserWebinarPlans = () => {
    return {
        type: planActionConstants.USER_WEBINAR_PLAN_LOADING
    }
}
const fetchUserWebinarPlans = async () => {
    return await fetchDataWithCsrf(null, 'subscriptions/getSubscriptionData')
}
const loadingUserWebinarPlansSuccess = (data) => {
    return {
        type: planActionConstants.USER_WEBINAR_PLAN_LOADING_SUCCESS,
        payload:data
    }
}
function getUserWebinarPlanWebinarList(data,page) {
    return (async (dispatch) => {
        dispatch(loadingUserWebinarPlanWebinarList())
        let r = await fetchUserWebinarPlanWebinarList(data,page)
        if (!r.success) {
            throw new Error("err")
        }
        dispatch(userWebinarPlanWebinarListComplete(r))
    })
}

const fetchUserWebinarPlanWebinarList = async (data,page) => {
    return await fetchDataWithCsrf(data, 'subscriptions/getWebinarList?page=' + page + '&sort=rank&direction=asc')
}
const loadingUserWebinarPlanWebinarList = () => {
    return {
        type: planActionConstants.USER_WEBINAR_PLAN_WEBINAR_LIST_LOADING
    }
}
const userWebinarPlanWebinarListComplete = (data) => {
    return {
        type: planActionConstants.USER_WEBINAR_PLAN_WEBINAR_LIST_COMPLETE,
        payload: data
    }
}
function getUserWebinarPlanWebinarListNextPage(data,page) {
    return (async (dispatch) => {
        dispatch(loadingUserWebinarPlanWebinarListNextPage())
        let r = await fetchUserWebinarPlanWebinarList(data,page)
        if (!r.success) {
            throw new Error("err")
        }
        dispatch(userWebinarPlanWebinarListCompleteNextPage(r))
    })
}
const loadingUserWebinarPlanWebinarListNextPage = () => {
    return {
        type: planActionConstants.USER_WEBINAR_PLAN_WEBINAR_LIST_LOADING_NEXT_PAGE
    }
}
const userWebinarPlanWebinarListCompleteNextPage = (data) => {
    return {
        type: planActionConstants.USER_WEBINAR_PLAN_WEBINAR_LIST_COMPLETE_NEXT_PAGE,
        payload: data
    }
}
const addWebinarToPlan = (obj)=>{
    return (async(dispatch)=>{
        dispatch(addingWebinarToPlan(obj))
        let r = await addWebinarToUserPlan(obj)
        if(!(r.status === 'success')){
            alert(r.status)
            throw new Error("")
        }
        dispatch(addWebinarToPlanSuccess(obj))
    })
}
const addingWebinarToPlan = (obj)=>{
    return {
        type: planActionConstants.ADD_WEBINAR_TO_PLAN_LOADING,
        payload: obj
    }
}
const addWebinarToUserPlan = async (obj)=>{
    return await fetchDataWithCsrf(obj, 'subscriptions/addUserSubscriptionItems/')

}
const addWebinarToPlanSuccess = (obj)=>{
    return {
        type: planActionConstants.ADD_WEBINAR_TO_PLAN_LOADING_DONE,
        payload: obj
    }
}
export const webinarPlanAction = {
    getUserWebinarPlanWebinarList,
    getUserWebinarPlanWebinarListNextPage,
    getUserPlansDetails,
    addWebinarToPlan
}