import React, { useState, useLayoutEffect } from 'react'
import AuthorLmsCourseContent from './AuthorLmsCourseContent'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthorLmsSection from './AuthorLmsSection'
import { useDispatch, useSelector } from 'react-redux'
import { authorLmsAction } from '../redux/actions/authorLmsAction'
import { courseTestAction } from '../redux/actions/courseTestAction'
import LoadingOverlay from 'react-loading-overlay'
import MoonLoader from 'react-spinners/MoonLoader'
import logo from '../new_elm_logo.png'
import smallLogo from '../small-elm-logo.png';
import userHeader from '../images/user_default.png'

import right from '../right.png'
import left from '../left.png'
import { Modal, Dropdown } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import Forum from './Forum'
import TestSectionRighrBar from '../Element/TestSectionRightBar'
import TestSectionRightResultBar from '../Element/TestSectionRightResultBar'
import Notes from '../Element/Notes'
import LiveClassRightBar from '../Element/LiveClassRightBar';
import LiveSessionVideo from '../Element/LiveSessionVideo';
import { myaccountAction } from '../redux/actions/myaccountAction'
import { NavLink } from "react-router-dom";


let intv
let time_for_each_question
export default function MainComponent() {

    const [lpanelopen, setLpanelopen] = useState('')

    let course_id = localStorage.getItem('course_id')

    const dispatch = useDispatch()

    const course = useSelector(state => state.authorLmsData)

    const coursetest = useSelector(state => state.coursetest)
    const userAccount = useSelector(state => state.myaccount)

    const [showCourseContentRight, setshowCourseContentRight] = useState(true)
    
    const [randomValue, setRandomValue] = useState(1);

    useLayoutEffect(() => {
        getCourseInfo()
        getUserData()
    }, [])

    const getCourseInfo = async () => {
        try {
            await dispatch(authorLmsAction.getInitialDataOnLoad())
        } catch (err) {
            throw new Error("e")
        }
    }
    function getUserData() {
        try {
            dispatch(myaccountAction.getUserDetails())
        } catch (error) {
        }
    }
    const setOpenSection = (index) => {
        dispatch(authorLmsAction.openSection(index))
    }

    const setOpenChapter = async (section_index, chapter_id, chapter_index, is_unit_loaded) => {

        try {
            await dispatch(authorLmsAction.openChapter(section_index, chapter_id, chapter_index, is_unit_loaded))

        } catch (err) {
            throw new Error('err')
        }
    }

    const setSelectionData = (sec_id, chap_id, unit_id, chapter_to_be_shown = false) => {
        try {
            dispatch(authorLmsAction.setSelectedData(sec_id, chap_id, unit_id, chapter_to_be_shown))
            setMobilePanelClass('')
            setIsShowLiveClass(false)
            setshowCourseContentRight(true)
            setRandomValue(Math.random());
        } catch (err) {
            throw new Error(err)
        }
    }

    const getbloghtml = async (blog_url) => {

        try {
            let uri = 'https://www.elearnmarkets.com/blog/wp-json/wp/v2/posts?slug=' + blog_url.replace('https://www.elearnmarkets.com/blog/', '').replace('/', '')
            let r = await fetch(uri).then((resp) => { return resp.json() })
            let r2 = await r[0].content.rendered
            return r2
        } catch (err) {
            throw new Error(err)
        }
    }

    const setCourseTestData = async (gl_id, unit_selected) => {

        try {
            await dispatch(courseTestAction.gettestdetail(gl_id, course_id, unit_selected))
        } catch (err) {
            throw new Error(err)
        }
    }

    const setCourseTestStarted = () => {
        try {
            dispatch(courseTestAction.setTestStarted())
            setLpanelopen('')
            startTimerForEachQuestion()
            setshowCourseContentRight(false)
        } catch (err) {
            throw new Error(err)
        }
    }

    const saveAnswerandSetCurrentQuestion = async (answerObj, current_selected_question_index) => {
        try {
            await dispatch(courseTestAction.answerSaveAnswerandSetCurrentQuestionlected(answerObj, current_selected_question_index))
            clearInterval(time_for_each_question)
            startTimerForEachQuestion()
        } catch (err) {
            throw new Error(err)
        }
    }
    const gotoNextUnit = async (is_next) => {
        if (course.course_initial_data_loading === true) {
            return
        }

        let { section_selected, chapter_selected, unit_selected } = JSON.parse(localStorage.getItem('current_object'))
        let c_sec1 = [...course.course_data]

        let selected_section_data = c_sec1.filter((arr) => { return parseInt(arr.id) === parseInt(section_selected) })
        let selected_chapter_data = selected_section_data[0].course_chapter_versions.filter((arr) => { return parseInt(arr.id) === parseInt(chapter_selected) })
        let selected_unit_data = selected_chapter_data[0].unit.filter((arr) => { return parseInt(arr.id) === parseInt(unit_selected) })

        let total_chapter_in_section = selected_section_data[0].course_chapter_versions.length
        let last_or_first_chapter_id = null
        let total_unit_in_chapter = selected_chapter_data[0].unit.length
        let new_unit_selected_id = null
        let new_unit_selected_index = null
        let new_chapter_selected_index = null
        let last_or_first_unit = null
        let new_section_index = null

        let new_selected_chapter = []

        if (is_next === 1) {
            last_or_first_unit = selected_chapter_data[0].unit[total_unit_in_chapter - 1]
            last_or_first_chapter_id = selected_section_data[0].course_chapter_versions[total_chapter_in_section - 1].id
        } else if (is_next === 0) {
            last_or_first_unit = selected_chapter_data[0].unit[0]
            last_or_first_chapter_id = selected_section_data[0].course_chapter_versions[0].id
        }

        if (parseInt(selected_unit_data[0].id) !== parseInt(last_or_first_unit.id)) {
            //not the last unit so point to next unit
            selected_chapter_data[0].unit.map((u, i) => {
                if (u.id === unit_selected) {
                    if (is_next === 1) {
                        new_unit_selected_index = i + 1
                    } else {
                        new_unit_selected_index = i - 1
                    }
                }
            })
            new_unit_selected_id = selected_chapter_data[0].unit[new_unit_selected_index].id
            setSelectionData(section_selected, chapter_selected, new_unit_selected_id)
        } else {
            if (chapter_selected !== last_or_first_chapter_id) {
                //change the chapter and also set the first unit as opened
                selected_section_data[0].course_chapter_versions.map((c, i) => {
                    if (c.id === chapter_selected) {
                        if (is_next === 1) {
                            new_chapter_selected_index = i + 1
                        } else {
                            new_chapter_selected_index = i - 1
                        }
                    }
                })
                new_selected_chapter[0] = selected_section_data[0].course_chapter_versions[new_chapter_selected_index]

                c_sec1.map((sec, i) => {
                    if (parseInt(sec.id) === parseInt(section_selected)) {
                        new_section_index = i
                    }
                })
                if (new_selected_chapter[0].is_unit_loaded) {
                    new_selected_chapter[0].showUnit = true
                    if (is_next === 1) {
                        new_unit_selected_id = new_selected_chapter[0].unit[0].id
                    }
                    else {
                        new_unit_selected_id = new_selected_chapter[0].unit[new_selected_chapter[0].unit.length - 1].id
                    }


                    await setOpenChapter(new_section_index, new_selected_chapter[0].id, new_chapter_selected_index, true)
                    setSelectionData(section_selected, new_selected_chapter[0].id, new_unit_selected_id, true)
                } else {
                    await gotoNextChapter(new_section_index, new_selected_chapter[0].id, new_chapter_selected_index)
                }
            } else {
                //end of chapter and section - goto next section
                let current_section_index = null
                c_sec1.map((sec, i) => {
                    if (parseInt(sec.id) === parseInt(section_selected)) {
                        current_section_index = i
                    }
                })
                if (is_next === 0 && current_section_index === 0) {
                    //at start 
                    alert("You are at start")
                }
                else {
                    if (is_next === 0) {
                        new_section_index = current_section_index - 1
                        //setOpenSection()
                    } else {
                        new_section_index = current_section_index + 1
                    }
                    setOpenSection(new_section_index)
                    await setOpenChapter(new_section_index, c_sec1[new_section_index].course_chapter_versions[0].id, 0, false)
                    let new_course = [...course.course_data]
                    setSelectionData(new_course[new_section_index].id, new_course[new_section_index].course_chapter_versions[0].id, new_course[new_section_index].course_chapter_versions[0].unit[0].id, true)
                }
            }
        }

    }
    /* if next chapter data is not loaded */
    async function gotoNextChapter(new_section_index, chapter_id, chapter_index) {
        await setOpenChapter(new_section_index, chapter_id, chapter_index, false)
        gotoNextUnit(1)
    }

    const onvideoseeking = () => {
        console.log("seeking main");
    }

    const onvideoend = () => {
        let { unit_selected, section_selected, chapter_selected } = JSON.parse(localStorage.getItem('current_object'))
        unitComplete(section_selected, chapter_selected, unit_selected)
        gotoNextUnit(1)
    }

    const onvideoplaying = (duration) => {
        let { section_selected, chapter_selected, unit_selected } = JSON.parse(localStorage.getItem('current_object'))
        let c_sec1 = [...course.course_data]

        let selected_section_data = c_sec1.filter((arr) => { return parseInt(arr.id) === parseInt(section_selected) })
        let selected_chapter_data = selected_section_data[0].course_chapter_versions.filter((arr) => { return parseInt(arr.id) === parseInt(chapter_selected) })
        let selected_unit_data = selected_chapter_data[0].unit.filter((arr) => { return parseInt(arr.id) === parseInt(unit_selected) })

        dispatch(authorLmsAction.setVideoTime(duration, selected_unit_data[0].filecontent.id, section_selected, chapter_selected, unit_selected))
    }

    const unitComplete = async (sec_id, chap_id, unit_id) => {
        try {
            await dispatch(authorLmsAction.completeUnit(sec_id, chap_id, unit_id))
        } catch (err) {
            alert("Error" + err)
        }
    }

    const gotoLogin = () => {
        window.location.href = process.env.REACT_APP_NEXT_HOME_URL + 'login'
    }


    const [showTestConfirmModel, setShowTestConfirmModel] = useState(false)
    const [timeR, setTimeR] = useState("00:00:00")
    const [timeT, setTimeT] = useState(0)
    const [totalTimeTaken, setTotalTimeTaken] = useState(0)
    function startClock(total_time) {
        let i = 0
        intv = setInterval(() => {
            i++
            const ct = getRemainToHHMMSS(total_time * 60 - i)
            setTimeR(ct)
            setTimeT(i)
        }, 1000)

    }
    const getRemainToHHMMSS = (remaining) => {
        let secData = remaining;
        let minutes = ("00" + Math.floor(secData / 60)).slice(-2);
        let seconds = ("00" + (secData % 3600) % 60).slice(-2);
        if (secData <= 0) {
            alert("Times up");
            finishTest()
        }
        return minutes + ":" + seconds;
    }

    const finishTest = async () => {
        clearInterval(intv)
        clearInterval(time_for_each_question)
        setTimeT(0)
        setTimeR("00:00:00")
        setShowTestConfirmModel(false)
        try {
            let { section_selected, chapter_selected, unit_selected } = course
            let all_test_question1 = [...coursetest.course_test_data]
            let reqObj = {
                course_id: course_id,
                currentObj: {

                    section_id: section_selected,
                    chapter_id: chapter_selected,
                    unit_id: unit_selected,
                },
                data: all_test_question1[0],
                timeTaken: timeT
            }
            setTotalTimeTaken(timeT)
            await dispatch(courseTestAction.submitTest(reqObj))
        } catch (err) {

            alert("error occurred generating result")
        }
    }

    const [isCancellTest, setIsCancellTest] = useState(false)
    const cancelTest = () => {
        clearInterval(intv)
        clearInterval(time_for_each_question)
        setTimeT(0)
        setTimeR("00:00:00")
        dispatch(courseTestAction.cancelTest())
        setLpanelopen('')
        setIsCancellTest(false)
        gotoNextUnit(1)
    }


    const [timeForEachQuestion, setTimeForEachQuestion] = useState(0)
    const startTimerForEachQuestion = () => {
        let t = 0
        time_for_each_question = setInterval(() => {
            t++
            setTimeForEachQuestion(t)
        }, 1000);
    }

    const [mobilePanelClass, setMobilePanelClass] = useState('')

    const openMobileSideMenu = () => {
        setLpanelopen('')
        setMobilePanelClass('open-for-mobile-right-menu')
    }

    const [bottomSectionShow, setBottomSectionShow] = useState(1)


    const [isShowLiveClass, setIsShowLiveClass] = useState(false)

    const showLiveClass = () => {
        setIsShowLiveClass(true)
        setshowCourseContentRight(false)
        setLpanelopen('')
        dispatch(authorLmsAction.loadBatches())
    }

    const showCourseContentRightBar=()=>{
        setIsShowLiveClass(false)
        setshowCourseContentRight(true)
    }

    const getBatchSessions = (batch_id) => {
        dispatch(authorLmsAction.getBatchSessions(batch_id))
    }

    const openSession = (batch_index, session_index) => {
        dispatch(authorLmsAction.openSession(batch_index, session_index))
    }
    const setSessionVideo = (data) => {
        dispatch(authorLmsAction.setSessionVideo(data))
    }

    const markAsLearn = () => {
        if (course.unit_data.file_type_id !== 2 && course.unit_data.file_type_id !== 13 && course.unit_data.file_type_id !== 7) {
            unitComplete(course.section_selected, course.chapter_selected, course.unit_selected)
        }
    }

    const logout = () => {
        sessionStorage.clear()
        window.location.href = process.env.REACT_APP_NEXT_HOME_URL + "api/auth/signout"
    }

    if (course && course.course_initial_data_loading) {
        return (
            <main role="main">
                <div className="container-fluid" style={{ height: '300px' }}>
                    <div style={{ marginTop: '230px' }}>
                        <LoadingOverlay
                            active={course.course_initial_data_loading}
                            spinner={<MoonLoader color="#ee9949" />}
                        />
                    </div>
                </div>
            </main>
        )
    } if (course && course.user_not_logged_in) {
        return (
            <Modal show={course.user_not_logged_in}>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Session Expired </Modal.Title>
                </Modal.Header>
                <Modal.Body>Please log in again</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => gotoLogin()}>
                        Goto Login
          </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    else {

        return (
            <main role="main">
                <div className="container-fluid pl-0 pr-0 overflow-hidden" style={{ background: '#fff' }}>
                    <div className="top-header-style pl-1 pr-1 header-for-pc">
                        <div className="row">
                            <div className="col-lg-2 col-2 text-center">
                                <a href={process.env.REACT_APP_NEXT_HOME_URL} target="_blank" style={{ display: 'block' }}>
                                    <img src={logo} alt="logo" className="img-fluid lms-elm-icon" />
                                </a>
                            </div>
                            <div className="col-lg-7 col-10" style={{ boxShadow: 'inset 4px 0px 8px #f3f3f3' }}>
                                <p className="course-name-top-header">
                                    {course.course_name}
                                </p>
                            </div>
                            <div className="col-lg-3 col-10 pl-0">
                              
                                <NavLink to="/hosted-courses" className="go-to-my-account">
                                    Exit<i class="fa fa-power-off ml-1" aria-hidden="true"></i>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="header-for-mobile">
                        <div className="row">
                            <div className="col-4 text-left">
                                <a href="#" onClick={() => openMobileSideMenu()} className="right-sideopen-btn">
                                    <i class="fa fa-bars" aria-hidden="true"></i>
                                </a>
                                <a href={process.env.REACT_APP_NEXT_HOME_URL} style={{ display: 'block' }}>
                                    <img src={smallLogo} alt="logo" className="img-fluid logo-for-mobile" />
                                </a>
                            </div>
                            <div className="col-8 pl-0">
                               
                                <NavLink to="/hosted-courses" className="go-to-my-account">
                                    Exit<i class="fa fa-power-off ml-1" aria-hidden="true"></i>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <Router>

                    <div className={`main-container-box ${lpanelopen}`}>
                        <div className="left-section-for-elearn">
                            <div className="e-learning-content-section">
                                <button className="show-side-menu-lms-btn" onClick={() => setLpanelopen('')}><i className="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>Course content</button>
                                {
                                     showCourseContentRight &&
                                    <span>
                                        <button className="previous-button" onClick={() => gotoNextUnit(0)}><img src={left} alt="left" className="next-previous-icon" /></button>
                                        <button className="next-button" onClick={() => gotoNextUnit(1)}><img src={right} alt="right" className="next-previous-icon" /></button>
                                    </span>
                                }
                                {
                                    course.has_batch &&
                                    <button className="show-side-menu-lms-btn" style={{ top: '100px' }} onClick={() => showLiveClass()}><i className="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>Live Classes</button>
                                }
                                <div className="row  justify-content-md-center">
                                    <div className="col-lg-12">
                                        <Switch>
                                            <Route path={`/author-lms/${course_id}`}>

                                                {
                                                    course && !course.course_initial_data_loading && !isShowLiveClass && (
                                                        <div>
                                                            <AuthorLmsSection
                                                                key={randomValue}
                                                                unit_data={course.unit_data}
                                                                getbloghtml={getbloghtml}
                                                                gettestdetail={setCourseTestData}
                                                                unit_selected={course.unit_selected}
                                                                course_test_info={coursetest.course_test_data}
                                                                course_test_data_success_value={coursetest.course_test_data_success_value}
                                                                course_test_all_data={coursetest.course_test_all_data}
                                                                course_test_loading={coursetest.course_test_loading}
                                                                startCourseTest={setCourseTestStarted}
                                                                saveAnswerandSetCurrentQuestion={saveAnswerandSetCurrentQuestion}
                                                                isCourseTestStarted={coursetest.course_test_started}
                                                                current_question_no={coursetest.current_question_no}
                                                                onvideoseeking={onvideoseeking}
                                                                onvideoend={onvideoend}
                                                                onvideoplaying={onvideoplaying}
                                                                section_selected={course.section_selected}
                                                                chapter_selected={course.chapter_selected}
                                                                showTestConfirmModel={showTestConfirmModel}
                                                                setShowTestConfirmModel={setShowTestConfirmModel}
                                                                startClock={startClock}
                                                                timeR={timeR}
                                                                finishTest={finishTest}
                                                                course_test_submit={coursetest.course_test_submit}
                                                                course_test_result_loading={coursetest.course_test_result_loading}
                                                                course_test_result={coursetest.course_test_result}
                                                                next_question_loading={coursetest.next_question_loading}
                                                                timeForEachQuestion={timeForEachQuestion}
                                                                course_completion_percentage_info={course.course_completion_percentage_info}
                                                                selected_course_unit={course.selected_course_unit}
                                                                unitcomplete={unitComplete}
                                                                markAsLearn={markAsLearn}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    isShowLiveClass &&
                                                    <LiveSessionVideo
                                                        session_video_set={course.session_video_set}
                                                    />
                                                }

                                            </Route>
                                        </Switch>

                                    </div>
                                </div>



                            </div>
                           
                        </div>
                        {
                            course && showCourseContentRight &&
                            <AuthorLmsCourseContent
                                onclose={() => {
                                    setLpanelopen("display-none-side-menu-lms")
                                    setMobilePanelClass('')
                                }
                                }
                                course_info={course}
                                opensection={setOpenSection}
                                openchapter={setOpenChapter}
                                setselectiondata={setSelectionData}
                                course_id={course_id}
                                unitcomplete={unitComplete}
                                mobilePanelClass={mobilePanelClass}
                                showLiveClass={showLiveClass}
                                setshowCourseContentRight={setshowCourseContentRight}
                            />
                        }
                        {
                            isShowLiveClass && !showCourseContentRight &&
                            <LiveClassRightBar
                                setIsShowLiveClass={setIsShowLiveClass}
                                course_batch_data={course.course_batch_data}
                                course_batch_loading={course.course_batch_loading}
                                getBatchSessions={getBatchSessions}
                                openSession={openSession}
                                setSessionVideo={setSessionVideo}
                                mobilePanelClass={mobilePanelClass}
                                showCourseContentRightBar={showCourseContentRightBar}
                            />
                        }

                        {
                            coursetest.course_test_started && !coursetest.course_test_submit && !showCourseContentRight &&
                            <div>
                                <Modal show={isCancellTest}>
                                    <Modal.Header closeButton={false}>
                                        <Modal.Title>Cancel Test  </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Do you want to cancel the test?</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="warning" onClick={() => cancelTest()}>
                                            Yes
                                        </Button>
                                        <Button variant="default" onClick={() => setIsCancellTest(false)}>No</Button>
                                    </Modal.Footer>
                                </Modal>
                                <TestSectionRighrBar
                                    course_test_info={coursetest.course_test_data}
                                    cancelTest={() => setIsCancellTest(true)}
                                    current_question_no={coursetest.current_question_no}
                                    setCurrentQuestion={saveAnswerandSetCurrentQuestion}
                                />
                            </div>
                        }
                        {
                            coursetest.course_test_started && coursetest.course_test_submit && !showCourseContentRight &&
                            <div>
                                <TestSectionRightResultBar
                                    course_test_result_loading={coursetest.course_test_result_loading}
                                    course_test_result={coursetest.course_test_result}
                                    totalTimeTaken={totalTimeTaken}
                                    setCourseTestStarted={setCourseTestData}
                                    course_test_info={coursetest.course_test_data}
                                    unit_selected={course.unit_selected}
                                    unit_data={course.unit_data}
                                    gotoNextUnit={gotoNextUnit}
                                    setshowCourseContentRight={setshowCourseContentRight}
                                />
                            </div>
                        }
                    </div>
                </Router>
            </main>
        );
    }
}