import React, { useEffect, useState } from 'react'
import { siteConstants } from '../constants'
import Progress from 'react-progressbar'
import { getDataWithToken } from '../services/fetchDataWithCsrf'

const Game = (props) => {
    const [gameStarted, setGameStarted] = useState(false)
    const [gameInfo, setGameInfo] = useState(null)
    const [loading, setLoading] = useState(true)
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentAttempt, setCurrentAttempt] = useState(0)
    const [answers, setAnswers] = useState([])
    const [remain, setRemain] = useState(1)
    const [totalScore, setTotalScore] = useState(0)

    async function getGameInfo() {
        let r1 = await getDataWithToken(`home/loadGlossaryWord/${props.word_glossary_id}`)
        setGameInfo(r1.data.word_glossary_words)
        setLoading(false)
        setGameStarted(true)
    }
    const keyboardBtnClick = (value) => {

        if (remain > 0 && currentAttempt < 6) {
            let ans = answers ? [...answers] : null
            let f = ans.includes(value)
            let w = gameInfo[currentQuestion].word
            let attemp_true = true

            if (w.toUpperCase().includes(value)) {
                //right letter pressed
                attemp_true = false
            } else if (ans.includes(value)) {
                //already pressed
                attemp_true = false
            }

            if (!f) {
                ans.push(value)
                setAnswers(ans)
            }
            if (attemp_true) {
                setCurrentAttempt(currentAttempt + 1)
            }
            let c = 0
            let wlen = w.replace('\\/r\\/n', '').length
            let spc = 0
            let rem
            let score = 0
            for (let i = 0; i < wlen; i++) {
                if (w.charCodeAt(i) === 13 || w.charCodeAt(i) === 32 || w.charCodeAt(i) === 10) {
                    spc++
                }
                if (ans.includes(w[i].toUpperCase())) {
                    c++
                }
            }
            rem = wlen - c - spc
            if (rem <= 0) {
                if (currentAttempt === 5) {
                    score = 100
                }
                else {
                    score = (6 - (currentAttempt + 1)) * 200
                }
            }
            setTotalScore(totalScore + score)
        }

    }
    function gotoNextQuestion() {
        setCurrentAttempt(0)
        setAnswers([])
        setCurrentQuestion(currentQuestion + 1)
        setRemain(1)
    }
    const GameKeyBoard = (cprops) => {
        let arr = []
        for (let i = 65; i <= 90; i++) {
            arr[i] = i
        }
        return (
            <div className="game-keyboard">
                {
                    arr.map((k, i) => (
                        <button className={cprops.currentAnswer.includes(String.fromCharCode(k)) && cprops.answer.toUpperCase().includes(String.fromCharCode(k)) ? 'game-btn success-btn' : cprops.currentAnswer.includes(String.fromCharCode(k)) ? 'game-btn wrong-btn' : 'game-btn'} key={i} onClick={() => cprops.keyboardBtnClick(String.fromCharCode(k))}>{String.fromCharCode(k)}</button>
                    ))
                }
            </div>
        )
    }

    const AnswerWord = (cprops) => {

        let w_obj = []

        let print_value
        let char_v
        let answer_value = cprops.answer.replace('\\/r\\/n', '')
        for (let i = 0; i < answer_value.length; i++) {
            char_v = answer_value.charAt(i).toUpperCase()
            if (cprops.currentAttempt >= 5) {
                print_value = char_v
            }
            else {
                print_value = (cprops.currentAnswer.indexOf(char_v) !== -1) ? char_v : '?'
            }
            if (answer_value.charCodeAt(i) === 10) {
                continue
            }
            if (answer_value.charCodeAt(i) === 13 || answer_value.charCodeAt(i) === 32) {
                print_value = " "
            }
            w_obj.push({ value: char_v, print_value })
        }
        if (w_obj !== null) {
            let l = 0
            w_obj.map((item) => {
                if (item.print_value === '?') {
                    l++
                }
            })
            cprops.setRemain(l)
        } else {
            cprops.setRemain(answer_value.length)
        }

        return (
            <div className="game-answer-block">
                {
                    w_obj !== null && w_obj.map((item, i) => (
                        <span className={item.print_value !== " " ? item.print_value ==='?' ? 'game-answer-letter' : 'game-answer-letter game-word-fill' : 'game-spacer'}>
                            {item.print_value}
                        </span>
                    ))
                }
            </div>
        )
    }

    return (
        <div className="section-class game-section-top-box">
            {
                !gameStarted && (
                    <div>
                        <p className="course-test-name">
                            Guess the word
                        </p>
                        <p className="game-open-tagline">
                            <span> Fundamental Analysis Simplified</span> 
                        </p>
                        <ol className="game-open-tagline-box">
                            <li>
                                This game will show you a clue to a term and also show the number of words/letters in the word.
                            </li>
                            <li>
                                You need to think and guess the correct word by clicking letters that form the word.

                            </li>
                            <li>
                                If you get a wrong letter, keep trying, as you get upto 6 wrong attempts.
                            </li>
                        </ol>
                        <button className="btn my-btn-primary mt-2 mb-4 ml-4" onClick={() => getGameInfo()}>Play Game</button>
                    </div>
                )
            }
            {
                gameStarted &&
                <>

                    { loading &&
                        <p>

                            loading
                        </p>
                    }
                    {
                        !loading && currentQuestion < gameInfo.length && (

                            <div style={{padding:'1.5rem'}}>
                                <div className="game-question-attempt-box">
                                    {
                                        (100 - currentAttempt * 20) > 0 &&
                                        <>
                                            <Progress completed={100 - currentAttempt * 20} />
                                            <span className="game-attempt-left">Attempts left : {6 - currentAttempt}</span>
                                        </>
                                    }
                                    {
                                        (100 - currentAttempt * 20) <= 0 &&
                                        <span className="game-attempt-left">No Attempt left</span>
                                    }
                                    <p className="mt-1">
                                        <small>
                                            <span className="pull-left">Total Score: {totalScore}</span>
                                            <span className="pull-right">Questions: {currentQuestion + 1} / {gameInfo.length}</span>
                                        </small>
                                    </p>
                                </div>

                                <p className="game-question">
                                    {
                                        gameInfo[currentQuestion].meaning.toUpperCase().replace(/\r/g, " ").replace(/\n/g, " ")
                                    }
                                </p>
                                <div className="game-section">

                                    <AnswerWord
                                        answer={gameInfo[currentQuestion].word}
                                        currentAnswer={answers}
                                        currentAttempt={currentAttempt}
                                        setCurrentAttempt={setCurrentAttempt}
                                        setRemain={setRemain}
                                        remain={remain}
                                    />
                                    <GameKeyBoard
                                        keyboardBtnClick={keyboardBtnClick}
                                        answer={gameInfo[currentQuestion].word}
                                        currentAnswer={answers}
                                    />
                                    {
                                        (currentAttempt >= 6 || remain <= 0) && (
                                            <button onClick={() => gotoNextQuestion()} className="game-next-btn">Next</button>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                        !loading && currentQuestion >= gameInfo.length && (
                            <div className="game-total-score-box">
                                <div className="game-total-score-inner-box">
                                    <div className="game-total-score">
                                        <p>
                                            Your Score 
                                        </p>
                                        <h1>
                                            {totalScore}
                                        </h1>
                                    </div>
                                   
                                </div>
                                
                            </div>
                        )
                    }

                </>
            }
        </div>
    )
}

export default Game