import { actionconstants } from '../actionconstants'
import { fetchDataWithCsrf } from '../../services/fetchDataWithCsrf'


const getCourseDiscussion = (course_id,page_no) => {
    return (async (dispatch) => {
        dispatch(loadingDiscussion())
        let r = await fetchDiscussion(course_id,page_no)
        if(parseInt(r.success)!== 1){
            throw new Error("error")
        }
        dispatch(loadingDiscussionSuccess(r))
    })
}

const loadingDiscussion = () => {
    return {
        type: actionconstants.COURSE_DISCUSSION_LOADING
    }
}

const fetchDiscussion = async (course_id, page_no) => {
    let r = await fetchDataWithCsrf({data: course_id}, `courses/getComments?page=${page_no}`)
    return r
}
const loadingDiscussionSuccess = (data)=>{
    return{
        type: actionconstants.COURSE_DISCUSSION_LOADING_COMPLETE,
        payload: data
    }
}
const getCourseDiscussionNextPage = (course_id,page_no) => {
    return (async (dispatch) => {
        dispatch(loadingDiscussionNextPage())
        let r = await fetchDiscussion(course_id,page_no)
        if(parseInt(r.success)!== 1){
            throw new Error("error")
        }
        dispatch(loadingDiscussionNextPageSuccess(r))
    })
}
const loadingDiscussionNextPage = () => {
    return {
        type: actionconstants.COURSE_DISCUSSION_NEXT_PAGE_LOADING
    }
}
const loadingDiscussionNextPageSuccess = (data)=>{
    return{
        type: actionconstants.COURSE_DISCUSSION_NEXT_PAGE_LOADING_COMPLETE,
        payload: data
    }
}
const postNewDiscussion = (data)=>{
    return (async(dispatch)=>{
        dispatch(loadingPostDiscussion())
        let r = await saveNewDiscussion(data)
        if(!r.success){
            throw new Error("Question Submission Failed")
        }
        dispatch(postNewDiscussionSuccess(r.data))
    })
}

const loadingPostDiscussion = ()=>{
    return {
        type: actionconstants.COURSE_DISCUSSION_POST_QUESTION_LOADING
    }
}

const postNewDiscussionSuccess= (data)=>{
    return {
        type: actionconstants.COURSE_DISCUSSION_POST_QUESTION_SUCCESS,
        payload: data
    }
}
const saveNewDiscussion = async (data)=>{
    let r = await fetchDataWithCsrf(data, "courses/saveCommentPost")
    return r
}
const postReplyInDiscussion = (data)=>{
    return (async(dispatch)=>{
        dispatch(loadinReplyPostDiscussion())
        let r = await saveNewDiscussion(data)
        if(!r.success){
            throw new Error("Question Submission Failed")
        }
        dispatch(postReplyDiscussionSuccess(r.data))
    })
}



const loadinReplyPostDiscussion = ()=>{
    return {
        type: actionconstants.COURSE_DISCUSSION_POST_QUESTION_LOADING
    }
}

const postReplyDiscussionSuccess= (data)=>{
    return {
        type: actionconstants.COURSE_DISCUSSION_POST_REPLY_SUCCESS,
        payload: data
    }
}
const saveNewVote = async (data)=>{
    let r = await fetchDataWithCsrf(data, 'courses/commentVote')
    let return_data
    if(r.success === 1){
      if(data.vote_type === 1)
      {
        return_data = {'voteType': data.vote_type ,'index_no':data.index_no, 'parent_index_no': data.parent_index_no, 'response' : r}
      }
      else{
        return_data = {'voteType': data.vote_type , 'index_no':data.index_no, 'parent_index_no': data.parent_index_no,  'response' : r}
      }
    }
    else{
       return_data = {'response' : r}
    }
    return return_data;
}
const saveVote = (data)=>{
    return (async(dispatch)=>{
        dispatch(loadinSaveVote())
        let r = await saveNewVote(data)
        if(!r.response.success){
            throw new Error("Vote Submission Failed")
        }
        dispatch(saveVoteSuccess(r))
    })
}
const loadinSaveVote = ()=>{
    return {
        type: actionconstants.COURSE_DISCUSSION_SAVE_VOTE_LOADING
    }
}
const saveVoteSuccess = (data) =>{
    return {
        type: actionconstants.COURSE_DISCUSSION_SAVE_VOTE_SUCCESS,
        payload: data
    }
}
export const courseDiscussionAction = {
    getCourseDiscussion,
    getCourseDiscussionNextPage,
    postNewDiscussion,
    postReplyInDiscussion,
    saveVote
}