import initialstate from '../initialstate'

import { actionconstants } from '../actionconstants'

export default function courseDiscussionReducer(state = initialstate, action) {
    switch (action.type) {
        case actionconstants.COURSE_DISCUSSION_LOADING:
            return {
                ...state,
                course_discussion_loading: true
            }
        case actionconstants.COURSE_DISCUSSION_LOADING_COMPLETE:
            return {
                ...state,
                course_discussion_loading: false,
                course_discussion: [action.payload]
            }
        case actionconstants.COURSE_DISCUSSION_SAVE_VOTE_LOADING:
            return {
                ...state,
                course_discussion_save_vote_loading: true,
                course_discussion_save_vote_success:false
            }
        case actionconstants.COURSE_DISCUSSION_SAVE_VOTE_SUCCESS:
    
            let discussion = [...state.course_discussion]
            if(action.payload.parent_index_no === null || action.payload.parent_index_no === ''){
                let commonParentArray = discussion[0].data[action.payload.index_no];
                if(parseInt(action.payload.voteType) === 1){
                    commonParentArray.total_up_vote= parseInt(commonParentArray.total_up_vote)+1
                }else if(parseInt(action.payload.voteType) === 2){
                    commonParentArray.total_down_vote= parseInt(commonParentArray.total_down_vote)+1
                }
            }
            else{
                let commonChildArray = discussion[0].data[action.payload.parent_index_no].child_course_comments[action.payload.index_no];
                if(commonChildArray.total_up_vote === '' && commonChildArray.total_down_vote === '')
                {
                    commonChildArray.total_up_vote = 0;
                    commonChildArray.total_down_vote = 0;
                }
                if(parseInt(action.payload.voteType) === 1){
                    commonChildArray.total_up_vote= parseInt(commonChildArray.total_up_vote)+1
                }else if(parseInt(action.payload.voteType) === 2){
                    commonChildArray.total_down_vote= parseInt(commonChildArray.total_down_vote)+1
                }  
            }
            
            return {
                ...state,
                course_discussion_save_vote_loading: false,
                course_discussion_save_vote_success:true,
                course_discussion:discussion
            }    
        case actionconstants.COURSE_DISCUSSION_NEXT_PAGE_LOADING:
            return {
                ...state,
                course_discussion_next_loading: true
            }
        case actionconstants.COURSE_DISCUSSION_NEXT_PAGE_LOADING_COMPLETE:
            let discussion_data = [...action.payload.data]
            let course_discussion = [...state.course_discussion]
            let d_data = [...course_discussion[0].data, ...discussion_data]

            let new_arr = []
            new_arr.push({
                data: d_data,
                lastpage: action.payload.lastpage,
                nextpage: action.payload.nextpage,
                total: action.payload.total
            })

            return {
                ...state,
                course_discussion_next_loading: false,
                course_discussion: new_arr
            }
        case actionconstants.COURSE_DISCUSSION_POST_QUESTION_LOADING:
            return {
                ...state,
                course_discussion_post_question_loading: true
            }
        case actionconstants.COURSE_DISCUSSION_POST_QUESTION_SUCCESS:
            let post_discussion_data = [...action.payload]
            let course_discussion_state = [...state.course_discussion]
            let new_data = [...post_discussion_data, ...course_discussion_state[0].data]
            course_discussion_state[0].data = new_data
            course_discussion_state[0].total = course_discussion_state[0].total + 1
            return {
                ...state,
                course_discussion: course_discussion_state,
                course_discussion_post_question_loading: false
            }
        case actionconstants.COURSE_DISCUSSION_POST_REPLY_SUCCESS:
            post_discussion_data = [...action.payload]
            course_discussion_state = [...state.course_discussion]
            let child_comment = []
            child_comment.push({
                comment_display_time: post_discussion_data[0].comment_display_time,
                create_date: post_discussion_data[0].create_date,
                description: post_discussion_data[0].description,
                id: post_discussion_data[0].id,
                parent_id: post_discussion_data[0].parent_id,
                user: post_discussion_data[0].user,
                total_up_vote:0,
                total_down_vote: 0
            })
            course_discussion_state[0].data.map((arr,index)=>{
                if(arr.id === post_discussion_data[0].parent_id){
                    let x = [...child_comment, ...arr.child_course_comments]
                    arr.child_course_comments = x
                }
            })

            return {
                ...state,
                course_discussion: course_discussion_state,
                course_discussion_post_question_loading: false
            }
        default:
            return {
                ...state
            }
    }
}