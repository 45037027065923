import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { fetchDataWithCsrf, getDataWithToken } from '../services/fetchDataWithCsrf'
const Notes = (props) => {

    const [notesData, setNotesData] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getUserCourseNote()
    }, [props.unit_selected])

    const getUserCourseNote = async () => {
        try {
            setLoading(true)
            let r = await getDataWithToken('courses/getUserCourseNote/' + props.course_id + '/' + props.unit_selected)

            if (r.data !== null) {
                setNotesData(r.data.description)
            } else{
                setNotesData("")
            }
            setLoading(false)
        } catch (error) {
        }
    }

    const saveNote = async () => {
        try {
            let r = await fetchDataWithCsrf({ course_id: props.course_id, unit_id: props.unit_selected, note: notesData }, 'courses/saveUserCourseNote')
        } catch (error) {

        }
    }

    return (
        <section>
            <div className="row">
                <div className="col-lg-12">
                {
                    !loading &&
                    <Form>
                        <Form.Control
                            onChange={(e) => setNotesData(e.target.value)}
                            type="textarea"
                            value={notesData}
                            as="textarea" 
                            rows={10}
                            placeholder="Write a new note here..."
                        />
                        <Button onClick={() => saveNote()} type="button" className="btn my-btn-primary mt-2 mb-4">Save</Button>
                    </Form>
                }
                </div>
                
                
            </div>
        </section>
    )
}

export default Notes