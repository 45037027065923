import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { siteConstants } from '../constants';

export default function ErrorModal(props) {
    const gotoLogin = () => {
        window.location.href = process.env.REACT_APP_NEXT_HOME_URL + 'login'
    }
    return (

        <Modal show={props.error}>
            <Modal.Header closeButton={false}>
                <Modal.Title>Session Expired </Modal.Title>
            </Modal.Header>
            <Modal.Body>Please log in again</Modal.Body>
            <Modal.Footer>
                <Button className="btn elm-btn" onClick={() => gotoLogin()}>
                    Go to Login
          </Button>
            </Modal.Footer>
        </Modal>
    )

}
