import React, { Component } from 'react'
import { siteConstants } from '../constants';


class Footer extends Component {
  render() {
    return (
      <footer className="footer-area">
        <div className="container-fluid pl-0 pr-0 overflow-hidden">
          <div className="row">
            <div className="col-lg-12">
              <img src={require('../images/footer_images/bottom-background.png')} className="img-fluid" />
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-1 col-3 text-left">
                <img src={require('../images/footer_images/elm-short-logo.png')} className="img-fluid" />
              </div>
              <div className="col-lg-4">
                <p className="footer-headding">
                  COMPANY / OVERVIEW
                </p>
                <div className="row">
                  <div className="col-6">
                    <ul className="footer-menu">
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + 'about-us'}>About us</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "reviews"}>Reviews</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "contact-us"}>Contact</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "privacy"}>Privacy</a>
                      </li>

                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="footer-menu">
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "terms-of-use"}>Terms of use</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "affiliate"}>Affiliate Program</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "register-teacher"}>Become a Teacher</a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <p className="footer-headding">
                  FEATURES
                </p>
                <div className="row">
                  <div className="col-6">
                    <ul className="footer-menu">
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "blog"}>Blog</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "webinars"}>Webinars</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "courses"}>Courses</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "webinar-plans"}>Plans</a>
                      </li>

                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="footer-menu">
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "financial-guides"}>Ebooks</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + 'jobs'}>Jobs</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "career"}>Career</a>
                      </li>
                      <li>
                        <a target="_blank" href={process.env.REACT_APP_NEXT_HOME_URL + "media"}>Media Coverage</a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <p className="footer-headding">
                  Address
                </p>
                <div className="row">
                  <div className="col-12">
                    <ul className="footer-menu">
                      <li>
                        <span>J-1/14, Block - EP and GP, 9th Floor</span>
                      </li>
                      <li>
                        <span>Sector V Saltlake City, Kolkata-700091, WB, IN </span>
                      </li>
                      <li>
                        <a href="mailto:info@elearnmarkets.com">info@elearnmarkets.com</a>
                      </li>
                      <li>
                        <a href="tel:919051622255">+91-9051622255</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 footer-image-container" style={{ borderTop: '1px dashed #ADC9E0', borderBottom: '1px dashed #ADC9E0', padding: '10px 15px', margin: '10px 0px' }}>
                <div className="row">
                  <div className="col-lg-6 col-7 text-left">
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.elearnmarkets.application">
                      <img src={require("../images/footer_images/google-play.png")} width="70px" />
                    </a>
                    <a target="_blank" href="https://apps.apple.com/us/app/elearnmarkets/id1521832221">
                      <img src={require("../images/footer_images/app-store.png")} width="70px" />
                    </a>
                  </div>
                  <div className="col-lg-6 col-5 text-right pl-0">
                    <a target="_blank" href="https://www.facebook.com/elearnmarkets"><img src={require("../images/footer_images/facebook.png")} width="20px" /></a>
                    <a target="_blank" href="https://www.instagram.com/elearnmarkets/?hl=en"><img src={require("../images/footer_images/instagram.png")} width="20px" /></a>
                    <a target="_blank" href="https://twitter.com/elearnmarkets"><img src={require("../images/footer_images/twiter.png")} width="20px" /></a>
                    <a target="_blank" href="https://www.linkedin.com/company/9399886"><img src={require("../images/footer_images/linkdin.png")} width="20px" /></a>
                    <a target="_blank" href="https://t.me/elearnmarkets"><img src={require("../images/footer_images/telegram.png")} width="20px" /></a>
                    <a target="_blank" href="https://www.youtube.com/c/Elearnmarkets"><img src={require("../images/footer_images/youtube.png")} width="20px" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6  text-left-pc">
                    <p className="footer-text">
                      An Initiative of Kredent InfoEdge Pvt. Ltd. SEBI Registration No. IHN3000007493
                    </p>
                  </div>
                  <div className="col-lg-6  text-right-pc">
                    <p className="footer-text">
                      Copyright © {new Date().getFullYear()} Elearnmarkets. All rights reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }

}


export default Footer;
