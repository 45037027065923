import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { myaccountAction } from "../redux/actions/myaccountAction";
import { siteConstants } from "../constants";
import { getCookie } from "../services/getCsrf";
import ReactTooltip from "react-tooltip";
import { fetchDataWithCsrf } from "../services/fetchDataWithCsrf";
export default function Rating(props) {
  const myaccount = useSelector((state) => state.myaccount);
  const dispatch = useDispatch();
  const [submitReviewStep, setSubmitReviewStep] = useState(1);
  const [reviewSubmitClicked, setReviewSubmitClicked] = useState(false);
  const [notRate ,setNotRate] = useState(false)
  useEffect(() => {
    ReactTooltip.rebuild();
    getUserRating();
  }, []);

  const getUserRating = async () => {
    try {
      await dispatch(
        myaccountAction.getUserRating(props.item_id, props.item_type)
      );
    } catch (err) {
      throw new Error(err);
    }
  };
  const getAvarageRating = async () => {
    try {
      await dispatch(
        myaccountAction.getAvarageRating(props.item_id, props.item_type)
      );
    } catch (err) {
      throw new Error(err);
    }
  };
  const ratingChanged = (newRating, index) => {
    setNotRate(false)
    myaccount.rating_data.rating = newRating;
  };

  const ratingChangedAll = (rating, index) => {
    myaccount.rating_data.feedback[index].answer = rating;
  };

  const handleChangeReview = (e) => {
    myaccount.rating_data.feedback[0].answer = e.target.value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let postdata = {
      item_id: props.item_id,
      item_type: props.item_type,
      create_date: new Date(),
      feedbackData: myaccount.rating_data.feedback,
      user: myaccount.rating_data.user_id,
      rating: myaccount.rating_data.rating,
    };
    if(parseInt(myaccount.rating_data.rating)<=0){
      setNotRate(true)
      return false
    }else{
      setNotRate(false)
    }
    setReviewSubmitClicked(true);
    let d = await fetchPostData("webinars/saveReview", postdata);
    if (props.is_need_details) {
      getAvarageRating();
    }
    setReviewSubmitClicked(false);
    setSubmitReviewStep(2);
  };
  const fetchPostData = async (url, data) => {
    let r1 = await fetchDataWithCsrf(data, url)
    setReviewSubmitClicked(false);
    return r1;
  };

  return (
    <div>
      {myaccount.rating_data_loading && <div>loading</div>}
      {!myaccount.rating_data_loading && (
        <div>
          {submitReviewStep === 2 && (
            <div className="">
              <div className="text-center">
                <p
                  style={{
                    border: "3px solid #20d00b",
                    color: "#20d00b",
                    fontSize: "31px",
                    width: "60px",
                    height: "60px",
                    lineHeight: "53px",
                    margin: "auto",
                    borderRadius: "50px",
                  }}
                >
                  <i className="fa fa-check" />
                </p>
                <p
                  style={{ fontSize: "1.4rem", color: "#12a700" }}
                  className="mt-3"
                >
                  Thank you for your feedback!
                </p>
              </div>
            </div>
          )}
          {submitReviewStep === 3 && (
            <div className="">
              <div className="text-center">
                <h4>You have already submitted your review.</h4>
              </div>
            </div>
          )}
          {submitReviewStep === 1 && (
            <div className="">
              <form
                method="post"
                acceptCharset="utf-8"
                style={{ padding: "1.5%" }}
                onSubmit={handleSubmit}
              >
                <p className="text-center">
                  Please rate <span>the following</span>{" "}
                </p>
                <div className="mt-2">
                  <div className="row">
                    <div className="col-lg-12">
                      {myaccount.rating_data.feedback.map(
                        (op, $index) =>
                          op.answer_type === 3 && (
                            <p className="modal-topic-ponits">
                              <span>{op.question}</span>
                              <span style={{ float: "right" }}>
                                <ReactStars
                                  count={5}
                                  onChange={(e) => ratingChangedAll(e, $index)}
                                  size={24}
                                  activeColor="#ffd700"
                                  value={op.answer}
                                />
                              </span>
                            </p>
                          )
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <p className="modal-topic-ponits">
                        <span>Overall experience</span>
                        <span style={{ float: "right" }}>
                          <ReactStars
                            count={5}
                            onChange={ratingChanged}
                            size={24}
                            activeColor="#ffd700"
                            value={myaccount.rating_data.rating}
                          />
                        </span>
                        {notRate && <span className="text-danger" style={{display:"block",marginTop:"4px", fontSize:"11px"}}>Please add rating.</span>} 
                      </p>
                     
                    </div>
                  </div>

                  <div className="form-group mt-2 row">
                    <div className="col-lg-12">
                      <textarea
                        className="form-control mb-0 modal-textarea"
                        rows={3}
                        id="textArea"
                        placeholder="Add your feedback"
                        defaultValue={myaccount.rating_data.feedback[0].answer}
                        onChange={handleChangeReview}
                      />
                    </div>
                  </div>
                </div>
                <p className="text-center">
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      props.modalClosed();
                    }}
                    className="mr-2"
                  >
                    <span> Cancel</span>
                  </a>
                  {!reviewSubmitClicked && (
                    <input
                      type="submit"
                      value="Submit"
                      className="btn elm-react-primary-btn btn-raised m-0 "
                    />
                  )}{" "}
                  {reviewSubmitClicked && (
                    <button
                      type="button"
                      className="btn elm-react-primary-btn btn-raised m-0 "
                      disabled="true"
                    >
                      <span>
                        <i className="fa fa-circle-o-notch fa-spin" /> Waiting..
                      </span>
                    </button>
                  )}
                </p>
              </form>
            </div>
          )}
        </div>
      )}
      <ReactTooltip />
    </div>
  );
}
