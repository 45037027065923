import { siteConstants } from "../constants";

export async function fetchDataWithCsrf(
  data,
  url,
  isFormData = false
) {
  let tokenData = await getToken();
  let uri = siteConstants.API_URL + "web/" + url;
  let h = {
    accessToken: tokenData.accessToken,
  };

  let reqops = {
    headers: h,
    credentials: "include",
    method: "POST",
    body: isFormData ? data : JSON.stringify(data),
  };
  let r = await fetch(uri, reqops).then((resp) => {
    return resp.json();
  });
  return r;
}
export async function getDataWithToken(url) {
  let tokenData = await getToken();
  let uri = siteConstants.API_URL + "web/" + url;
  let h = {
    accessToken: tokenData.accessToken,
  };

  let reqops = {
    headers: h,
    credentials: "include",
    method: "GET",
  };
  let r = await fetch(uri, reqops).then((resp) => {
    return resp.json();
  });
  return r;
}

export async function getCertificateDataWithToken(url, itemName="", forCertificate = true) {
  let tokenData = await getToken();
  let userFirstName = tokenData.user.name.split(/(?<=^\S+)\s/)[0];
  let uri = url;
  let h = {
    accessToken: tokenData.accessToken,
  };

  let reqops = {
    headers: h,
    credentials: "include",
    method: "GET",
  };
  let r = await fetch(uri, reqops).then((resp) => {
    resp.blob().then((blob) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = forCertificate ? `Certificate-${itemName}-${userFirstName}` : itemName;
      alink.click();
    });
  });
  return r;
}

export async function getCertificateImageWithToken(url) {
  let tokenData = await getToken();
  let uri = url;
  let h = {
    accessToken: tokenData.accessToken,
  };

  let reqops = {
    headers: h,
    credentials: "include",
    method: "GET",
  };
  let responseBolb = await fetch(uri, reqops).then((resp) => {
   return  resp.blob()
  });
  const urlBlob = URL.createObjectURL(responseBolb);
  return urlBlob;
}

export async function getToken() {
  let uri = process.env.REACT_APP_NEXT_HOME_URL+'api/auth/session';
  let h = {
    "X-CSRF-TOKEN": "",
  };

  let reqops = {
    headers: h,
    credentials: "include",
    method: "GET",
    //body: JSON.stringify(data)
  };

  let r = await fetch(uri, reqops).then((resp) => {
    return resp.json();
  });
  return r;
}
